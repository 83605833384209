import React, { useEffect, useState, useContext, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../globals/AuthContext";

//Chamada da api
import { ReadAccessTokenSafeIdController } from "../../utils/controllers/RequestAuthentication/ReadAccessTokenSafeIdController";

import styles from "../../styles/pages/Authentication.module.css";
import logo from "../../assets/images/logos/logo.png";

interface QrCodeResponseAuthenticationModel {
  code: string;
  state: string;
}

interface AuthenticationModel {
  AccessToken: Uint8Array;
  RedirectUri: string;
}

interface CallbackMessageModel {
  Status: string; // "Erro", "Warning", etc.
  Message: string;
}

const Authentication: React.FC = () => {
  const { queryResponseAuthentication, setQueryResponseAuthentication } =
    useContext(AuthContext) as {
      queryResponseAuthentication: string;
      setQueryResponseAuthentication: React.Dispatch<
        React.SetStateAction<string | undefined>
      >;
    };

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingAccessToken, setIsLoadingAccessToken] =
    useState<boolean>(false);
  const [objResponseAuth, setObjResponseAuth] =
    useState<QrCodeResponseAuthenticationModel>();
  const [requestExecuted, setRequestExecuted] = useState<boolean>(false); // Novo estado de controle
  const [authData, setAuthData] = useState<AuthenticationModel | null>(null); // Estado para AuthenticationModel

  const location = useLocation(); // Obtendo a localização atual da URL
  // const location =
  //   "/authentication?code=UM2R1U79689SU6U6YBZY0O6ZS1HBIJ14FTUWUM00YQ8Z8V6FRMOM77UMDL40EI9N5BOYD3RVQV9RET86E2LFY2U7UK7660XN0NK1HOZ6WIO99PY6KANCNUHE962WIYJ5&state=W6SyX7s62bIc3Pou8zGAyi8YCU9R5GoA4qGrCTd0wM0RV400r3";

  const renderReadAccessTokenSafeId = useCallback((res: unknown) => {
    try {
      if (res && typeof res === "object") {
        // Verificando se a resposta é do tipo CallbackMessageModel
        if ("Status" in res && typeof res.Status === "string") {
          const callbackMessage = res as CallbackMessageModel;

          if (callbackMessage.Status === "Erro") {
            console.error("Erro retornado pela API:", callbackMessage.Message);
            return;
          }

          if (callbackMessage.Status === "Warning") {
            console.warn("Aviso retornado pela API:", callbackMessage.Message);
            return;
          }

          if (callbackMessage.Status === "Ok") {
            console.log("Ok retornado pela API:", callbackMessage.Message);
            return;
          }
        }
        // Verificando se a resposta é do tipo AuthenticationModel
        else if ("AccessToken" in res && "RedirectUri" in res) {
          const authModel = res as AuthenticationModel;
          setAuthData(authModel);
          console.log("AuthenticationModel salvo com sucesso:", authModel);

          let urlApp = `${authModel.RedirectUri}/?auth=${authModel.AccessToken}`;

          window.location.href = urlApp;
        } else {
          console.error("Resposta da API não contém o formato esperado.");
        }
      } else {
        console.error("Formato de resposta inválido:", res);
      }
    } catch (error) {
      console.error("Erro ao processar a resposta da API:", error);
    }
  }, []);

  const readAccessTokenSafeId = useCallback(
    async (response_auth: string): Promise<void> => {
      try {
        const response = await ReadAccessTokenSafeIdController(response_auth);
        renderReadAccessTokenSafeId(response);
      } catch (error) {
        console.error("Erro ao realizar a requisição do Access Token:", error);
      }
    },
    [renderReadAccessTokenSafeId]
  );

  // useEffect para obter o código e o estado da URL e definir objResponseAuth
  // teste para debug
  // useEffect(() => {
  //   const searchParams = location.split("authentication?code=")[1];
  //   if (searchParams) {
  //     const code = searchParams.split("&state=")[0];
  //     const state = searchParams.split("&state=")[1];

  //     if (code && state) {
  //       const responseAuth: QrCodeResponseAuthenticationModel = {
  //         code,
  //         state,
  //       };
  //       debugger;
  //       setObjResponseAuth(responseAuth);
  //     }
  //   }
  // }, [location]);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const state = searchParams.get("state");

    if (code && state) {
      const responseAuth: QrCodeResponseAuthenticationModel = {
        code,
        state,
      };
      setObjResponseAuth(responseAuth);
    }
  }, [location, setObjResponseAuth]);

  useEffect(() => {
    if (objResponseAuth) {
      if (!isLoadingAccessToken) {
        setIsLoadingAccessToken(true);
        let response_auth = JSON.stringify(
          btoa(JSON.stringify(objResponseAuth))
        );
        (async () => await readAccessTokenSafeId(response_auth))();
        setRequestExecuted(true); // Marcar que a request foi executada
      }
    }
  }, [isLoadingAccessToken, objResponseAuth, readAccessTokenSafeId]);

  return (
    <div className={styles.Auth}>
      <header className={styles["Auth-header"]}>
        <img src={logo} className={styles["Auth-logo"]} alt="logo" />
        <br />
        <p>Agrocomply - Authentication</p>
      </header>
    </div>
  );
};

export default Authentication;
