import React, { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "../globals/AuthContext";
// import Home from "../pages/Home";
// import Desenvolvimento from "../pages/Desenvolvimento";
import Authentication from "../pages/Authentication";
// import Header from "../components/Header";
// import Loader from "../components/Loader";
import PrivateRoute from "./PrivateRoute"; // Importando o componente PrivateRoute

const Router: React.FC = () => {
  const { autenticated } = useContext(AuthContext) as {
    autenticated: boolean;
  };

  return (
    <main>
      <div id="raiz"></div>
      <BrowserRouter
        future={{
          v7_startTransition: true,
          v7_relativeSplatPath: true,
        }}
      >
        <Routes>
          <Route path="*" element={<Navigate to="/authentication" replace />} />
          <Route path="/authentication" element={<Authentication />} />
        </Routes>
      </BrowserRouter>

      {/* <Header />

      {autenticated === false ? (
        <Loader />
      ) : (
        <React.StrictMode>
          <BrowserRouter>
            <Routes>
              <Route path="/authentication" element={<Authentication />} />
              <Route
                path="/home"
                element={
                  <PrivateRoute autenticated={autenticated}>
                    <Home />
                  </PrivateRoute>
                }
              />
              <Route
                path="/home/desenvolvimento"
                element={
                  <PrivateRoute autenticated={autenticated}>
                    <Desenvolvimento />
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<Navigate to="/home" replace />} />
            </Routes>
          </BrowserRouter>
        </React.StrictMode>
      )} */}
    </main>
  );
};

export default Router;
