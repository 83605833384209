// import { DEFAULT_API_URLS } from "../../enum";
// import * as enviroment from "../../enviroment";
// import axios from "axios";

// const urlApi = DEFAULT_API_URLS[enviroment.getEnviroment()];

// export function ReadAccessTokenSafeIdController(response_auth) {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(`${urlApi}/ReadAccessTokenSafeId/`, {
//         headers: {
//           "Content-Type": "application/json",
//           response_auth: response_auth,
//         },
//       })
//       .then((res) => resolve(res))
//       .catch((err) => reject(err));
//   });
// }

import { DEFAULT_API_URLS } from "../../enum";
import * as environment from "../../environment";
import axios from "axios";

const urlApi = DEFAULT_API_URLS[environment.getEnvironment()];

export async function ReadAccessTokenSafeIdController(response_auth: string) {
  try {
    const response = await axios.get(`${urlApi}/ReadAccessTokenSafeId/`, {
      headers: {
        "Content-Type": "application/json",
        response_auth,
      },
    });
    return response.data; // Retornamos apenas os dados para facilitar o uso
  } catch (error) {
    console.error("Erro na requisição à API:", error);
    throw error; // Propaga o erro para ser tratado na função chamadora
  }
}
