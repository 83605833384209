import React, { createContext, useState, ReactNode } from "react";

interface AuthContextProps {
  accessToken: string[];
  setAccessToken: React.Dispatch<React.SetStateAction<string[]>>;
  autenticated: boolean;
  setAutenticated: React.Dispatch<React.SetStateAction<boolean>>;
  queryResponseAuthentication: string;
  setQueryResponseAuthentication: React.Dispatch<React.SetStateAction<string>>;
}

export const AuthContext = createContext<Partial<AuthContextProps>>({});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = (props) => {
  const [accessToken, setAccessToken] = useState<string[]>([]);
  const [autenticated, setAutenticated] = useState<boolean>(false);
  const [queryResponseAuthentication, setQueryResponseAuthentication] =
    useState<string>("");

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        setAccessToken,
        autenticated,
        setAutenticated,
        queryResponseAuthentication,
        setQueryResponseAuthentication,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
